<template>
  <div>
    <div class="min-h-screen bg-gradient flex flex-col items-center justify-center space-y-4">
      <logo class="h-12"/>
      <div class="text-center">
        <h1 class="text-xl text-app-green-darkest font-bold">{{ $t('404_heading') }}</h1>
        <p class="text-sm text-white">{{ $t('404_text')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  components: {
    Logo,
  },
};
</script>
